<template>
  <span>{{`${dayString}天${hourString}小时${minuteString}分${secondString}秒 `}}</span>
</template>

<script>
  export default {
    data() {
      return {
        day: '',
        hour: '',
        minute: '',
        second: '',
        timer: ''
      }
    },
    props: {
      remainTime: { // 倒计时间总秒数
        default: ''
      }
    },
    mounted() {
      if (this.remainTime > 0) {
        this.day = Math.floor((this.remainTime / 3600) / 24)
        this.hour = Math.floor((this.remainTime / 3600) % 24)
        this.minute = Math.floor((this.remainTime / 60) % 60)
        this.second = Math.floor(this.remainTime % 60)
        this.countDowm()
      }else{
        this.$emit('countDowmEnd', true);
      }
    },
    methods: {
      countDowm() {
        var self = this
        clearInterval(this.timer)
        this.timer = setInterval(function() {
          if (self.hour === 0 && self.day === 0) {
            if (self.minute !== 0 && self.second === 0) {
              self.second = 59
              self.minute -= 1
            } else if (self.minute === 0 && self.second === 0) {
              self.second = 0
              self.$emit('countDowmEnd', true)
              clearInterval(self.timer)
            } else {
              self.second -= 1
            }
          } else {
            if (self.minute !== 0 && self.second === 0) {
              self.second = 59
              self.minute -= 1
            }else if(self.hour === 0 && self.minute === 0 && self.second === 0){
              self.day -= 1
              self.hour = 23
              self.minute = 59
              self.second = 59
            } else if (self.minute === 0 && self.second === 0) {
              self.hour -= 1
              self.minute = 59
              self.second = 59
            } else {
              self.second -= 1
            }
          }
        }, 1000)
      },
      formatNum(num) {
        return num < 10 ? '0' + num : '' + num
      }
    },
    computed: {
      dayString() {
        return this.formatNum(this.day)
      },
      hourString() {
        return this.formatNum(this.hour)
      },
      minuteString() {
        return this.formatNum(this.minute)
      },
      secondString() {
        return this.formatNum(this.second)
      }
    }
  }
</script>

